<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Button</Heading>
			</GridContainer>
			<GridContainer size="12 9@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Colors</Heading>
							<Paragraph>There are five color variations for button colors that can be applied using the color prop.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" align="left" width="100%" space="2">
							<Stack direction="column row@sm" align="left" justify="left" space="2">
								<Stack>
									<Stage>
										<Button label="Primary" color="primary" />
									</Stage>
								</Stack>
								<Stack>
									<Stage>
										<Button label="Secondary" color="secondary" />
									</Stage>
								</Stack>
								<Stack>
									<Theme ar dl ed sd ds>
										<Stage>
											<Button label="Tertiary" color="tertiary" />
										</Stage>
									</Theme>
								</Stack>
							</Stack>
							<Theme ds>
								<Stack direction="column row@sm" align="left" justify="left" space="2">
									<Stack>
										<Stage>
											<Button label="Info" color="info" />
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Button label="Success" color="success" />
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Button label="Warning" color="warning" />
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Button label="Danger" color="danger" />
										</Stage>
									</Stack>
								</Stack>
							</Theme>
							<Theme ds sd ed hv wm dl>
								<Stack direction="column row@sm" align="left" justify="left" space="2">
									<Stack>
										<Stage>
											<Button label="Dark" color="dark" />
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Button label="Light" color="light" />
										</Stage>
									</Stack>
								</Stack>
							</Theme>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 9@lg">
				<Theme ar ed sd wm ds>
					<Card>
						<CardSection>
							<Stack direction="column" space="2" align="left">
								<Heading size="5">Outline</Heading>
								<Paragraph>Outline buttons provide a lighter weight button style. Add type="outline" to apply the style.</Paragraph>
							</Stack>
						</CardSection>
						<CardSection>
							<Stack direction="column" align="left" width="100%" space="2">
								<Stack direction="column row@sm" align="left" justify="left" space="2">
									<Stack>
										<Stage>
											<Button label="Primary" type="outline" color="primary" />
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Button label="Secondary" type="outline" color="secondary" />
										</Stage>
									</Stack>
									<Stack>
										<Theme ar dl ed sd ds>
											<Stage>
												<Button label="Tertiary" type="outline" color="tertiary" />
											</Stage>
										</Theme>
									</Stack>
								</Stack>
								<Theme ds>
									<Stack direction="column row@sm" align="left" justify="left" space="2">
										<Stack>
											<Stage>
												<Button label="Info" type="outline" color="info" />
											</Stage>
										</Stack>
										<Stack>
											<Stage>
												<Button label="Success" type="outline" color="success" />
											</Stage>
										</Stack>
										<Stack>
											<Stage>
												<Button label="Warning" type="outline" color="warning" />
											</Stage>
										</Stack>
										<Stack>
											<Stage>
												<Button label="Danger" type="outline" color="danger" />
											</Stage>
										</Stack>
									</Stack>
								</Theme>
								<Theme ds sd ed wm>
									<Stack direction="column row@sm" align="left" justify="left" space="2">
										<Stack>
											<Stage>
												<Button label="Dark" type="outline" color="dark" />
											</Stage>
										</Stack>
										<Stack>
											<Stage>
												<Button label="Light" type="outline" color="light" />
											</Stage>
										</Stack>
									</Stack>
								</Theme>
							</Stack>
						</CardSection>
					</Card>
				</Theme>
			</GridContainer>
			<GridContainer size="12 9@lg">
				<Theme ds ar sd wm>
					<Card>
						<CardSection>
							<Stack direction="column" space="2" align="left">
								<Heading size="5">Sizes</Heading>
								<Theme ar wm>
									<Paragraph margin="none">There is one size variation for buttons that can be set using the size prop.</Paragraph>
								</Theme>
								<Theme ds sd>
									<Paragraph margin="none">There are two size variations for buttons that can be set using the size prop.</Paragraph>
								</Theme>
							</Stack>
						</CardSection>
						<CardSection>
							<Stack direction="column" align="left" width="100%" space="2">
								<Stack direction="column row@sm" align="left" justify="left" space="2">
									<Stack>
										<Stage>
											<Theme ds sd ar>
												<Button label="Large" size="large" color="dark" />
											</Theme>
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Theme ds sd ar>
												<Button label="Large" size="large" color="primary" />
											</Theme>
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Theme ds sd wm>
												<Button label="Small" size="small" color="dark" />
											</Theme>
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Theme ds sd wm>
												<Button label="Small" size="small" color="primary" />
											</Theme>
										</Stage>
									</Stack>
								</Stack>
							</Stack>
						</CardSection>
					</Card>
				</Theme>
			</GridContainer>
			<GridContainer size="12 9@lg">
				<Theme ds>
					<Card>
						<CardSection>
							<Stack direction="column" space="2" align="left">
								<Heading size="5">Soft</Heading>
								<Paragraph>Soft buttons provide a less prominant button color. Add type="soft" to apply the style.</Paragraph>
							</Stack>
						</CardSection>
						<CardSection>
							<Stack direction="column" align="left" width="100%" space="2">
								<Stack direction="column row@sm" align="left" justify="left" space="2">
									<Stack>
										<Stage>
											<Button label="Primary" type="soft" color="primary" />
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Button label="Secondary" type="soft" color="secondary" />
										</Stage>
									</Stack>
									<Stack>
										<Theme ar dl ed sd ds>
											<Stage>
												<Button label="Tertiary" type="soft" color="tertiary" />
											</Stage>
										</Theme>
									</Stack>
								</Stack>
								<Stack direction="column row@sm" align="left" justify="left" space="2">
									<Stack>
										<Stage>
											<Button label="Info" type="soft" color="info" />
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Button label="Success" type="soft" color="success" />
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Button label="Warning" type="soft" color="warning" />
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Button label="Danger" type="soft" color="danger" />
										</Stage>
									</Stack>
								</Stack>
								<Stack direction="column row@sm" align="left" justify="left" space="2">
									<Stack>
										<Stage>
											<Button label="Dark" type="soft" color="dark" />
										</Stage>
									</Stack>
									<Stack>
										<Stage>
											<Button label="Light" type="soft" color="light" />
										</Stage>
									</Stack>
								</Stack>
							</Stack>
						</CardSection>
					</Card>
				</Theme>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Button Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">label</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">The text shown inside the button.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">url</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Provides a url for a navigational button.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">color</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										primary <br/>
										secondary <br/>
										<Theme ds ar dl ed sd>
											tertiary <br/>
										</Theme>
										<Theme ds>
											success <br/>
											info <br/>
											warning <br/>
											danger <br/>
										</Theme>
										<Theme ds dl ed hv sd wm>
											dark <br/>
											light <br/>
										</Theme>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">The base color that applies to the button component.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">type</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										<Theme ds ar ed sd wm>
											outline <br/>
										</Theme>
										<Theme ds>
											soft <br/>
										</Theme>
										<Theme dl hv>
											no options available <br/>
										</Theme>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Passes the type style variation to the button.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">size</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										<Theme ds sd wm>
											small <br/>
										</Theme>
										<Theme ds ar sd>
											large <br/>
										</Theme>
										<Theme dl ed hv>
											no options available <br/>
										</Theme>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the size of the button.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">full</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Makes the button full width of the container it is in.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">overlap</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Overlaps the element above the button.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">newTab</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">Boolean</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Opens a new tab when a navigational button is clicked.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>

		</Grid>
		
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		setTimeout(() =>{ 
			this.stageTheme(this.theme);
		}, 30);
  },
}
</script>

<style lang="scss" scoped>
</style>
